




















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getLoginUserInfo } from '@/api'
import { CommonModule } from '@/store/modules/common'
import { appFunc_senToken } from '@/utils/bridge'
@Component({
  name: 'Collection'
})
export default class Collection extends Vue {
  activeName = '0'

  ProcessArr = [
    {
      id: 7,
      title: '位置',
      content:
        '在获取你的授权后，你主动使用附近的人等功能分享位置信息时，allo会收集你的位置信息作为信息的一部分',
      purpose: '丰富用户的通讯社交体验，为用户提供基于位置信息的功能或服务'
    },
    {
      id: 8,
      title: '图片和视频',
      content:
        '在获取你的授权后，你主动使用发送/上传图片和视频等功能时，allo会收集你的图片和视频作为信息的一部分',
      purpose: '丰富用户的通讯社交体验，为用户提供基于位置信息的功能或服务'
    }
  ]

  informationArr = [
    {
      id: 9,
      title: '手机联系人',
      content:
        '在获取你的授权后，allo会收集手机联系人的特征，用于拨打电话和推荐朋友',
      purpose: '用于拨打电话和推荐朋友'
    }
  ]

  form = {
    // 头像
    headUrl: '',
    // 名字
    name: '',
    // 手机号
    mobile: '',
    // 性别
    sex: 0,
    // 地区
    address: '',
    // 签名
    selfSignature: ''
  }

  async mounted() {
    await this.getToken()

    this.getLoginUserInfo()
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '/////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
  }

  // 获取个人信息收集
  async getLoginUserInfo() {
    const { data } = await getLoginUserInfo()
    this.form = { ...data }
    console.log(this.form)
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }
}
